import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

Sentry.init({
    dsn: 'https://a77abeb2611c46eda87bf8a899423068@o1079338.ingest.sentry.io/6084014',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
});

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);
