import { useIntroductionContext } from '../context/IntroductionContext';
export const Introduction = () => {
    const state = useIntroductionContext();

    switch (state.type) {
        case 'LOADING':
            return <div></div>;
        case 'READY':
            return (
                <div
                    className="ck-content"
                    dangerouslySetInnerHTML={{ __html: state.introduction }}
                ></div>
            );
        default:
            return <h3>Welcome to the "Critical Sustainability Calendar"!</h3>;
    }
};
