import { NavLink } from 'react-router-dom';
import { NavBarItems } from '../NavBarItems';
import { StyledHeader } from '../styles/components/Header.style';
import { HOME_VIEW_SETTINGS } from '../views/HomeView';

export const Header = () => {
    return (
        <StyledHeader>
            <NavLink to={HOME_VIEW_SETTINGS.path}>
                <picture>
                    <source media="(min-width:350px)" srcSet="/logo_horizontal.png" />
                    <img src="/logo.png" alt="Sustainable Christmas Logo" />
                </picture>
            </NavLink>
            <div className="navigation">
                {NavBarItems.map((item, index) => (
                    <NavLink
                        key={index}
                        to={item.path}
                        className={({ isActive }) => (isActive ? 'link current' : 'link')}
                    >
                        {item.navTitle}
                    </NavLink>
                ))}
            </div>
        </StyledHeader>
    );
};
