import { captureMessage } from '@sentry/react';
import { createContext, useContext, useEffect, useState } from 'react';
import { httpService } from '../HttpService';
import { isTextSingleType, TextSingleType } from '../types';
type State =
    | { type: 'LOADING' }
    | { type: 'READY'; introduction: string }
    | { type: 'ERROR'; message: string };

const IntroductionContext = createContext<State>({ type: 'LOADING' });

export const useIntroductionContext = () => {
    const ctx = useContext(IntroductionContext);
    if (!ctx) throw new Error('Component beyond IntroductionContext!');
    return ctx;
};

export const IntroductionContextProvider: React.FC = (props) => {
    const { children } = props;
    const [state, setState] = useState<State>({ type: 'LOADING' });

    const setError = (message: string) => {
        captureMessage(message);
        setState({
            type: 'ERROR',
            message,
        });
    };

    const setup = async () => {
        const url = `/api/introduction`;
        const res = await httpService.fetch<TextSingleType>(url);

        if (!res.success) {
            return setError(
                'Failed to fetch introduction text. Check your internet connection and try again.'
            );
        }
        if (!isTextSingleType(res.data)) {
            return setError(
                'Failed to fetch introduction text. Check your internet connection and try again.'
            );
        }
        const newState: State = {
            type: 'READY',
            introduction: res.data.content,
        };
        setState(newState);
    };

    useEffect(() => {
        setup();
        // eslint-disable-next-line
    }, []);

    return (
        <IntroductionContext.Provider value={{ ...state }}>{children}</IntroductionContext.Provider>
    );
};
