import { Carousel } from 'react-responsive-carousel';
import { StyledPost } from '../styles/components/Post.style';
import { IPost } from '../types';

interface PostProps {
    post: IPost;
}

export const Post = (props: PostProps) => {
    const { post } = props;
    return (
        <StyledPost>
            <Carousel showArrows>
                {post.images.map((img) => (
                    <div key={img.id}>
                        <img src={img.url} alt={img.alternativeText} />
                    </div>
                ))}
            </Carousel>
            <div className="ck-content" dangerouslySetInnerHTML={{ __html: post.text }}></div>
        </StyledPost>
    );
};
