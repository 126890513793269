import { FC } from 'react';
import { Page } from '../components/Page';
import { ViewSettings } from '../types';
import { Calendar } from '../components/Calendar';
import { Introduction } from '../components/Introduction';

export const HOME_VIEW_SETTINGS: ViewSettings = {
    path: '',
    title: 'Sustainable Christmas | Advent Calendar',
    navTitle: 'Home',
};

export const HomeView: FC = () => {
    return (
        <Page title={HOME_VIEW_SETTINGS.title}>
            <Introduction />
            <Calendar />
        </Page>
    );
};
