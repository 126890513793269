export const theme = {
    colors: {
        background: '#FAFAFA',
        backgroundAccent: '#f4f4ed',
        text: '#112011',
        link: '#112099',
        accent: '#f2e2ba',
        scrollbarAccent: '#F4A442',
        shadow: '#b7b7b7',
    },
    borderRadius: {
        small: '2px',
        medium: '4px',
        grid: '8px',
    },
} as const;
