export interface ViewSettings {
    path: string;
    title: string;
    navTitle: string;
}

export interface Format {
    name: string;
    url: string;
    previewUrl?: string;
    width: number;
    height: number;
    ext: string;
    mime: string;
    size: number;
}

export interface Image extends Format {
    id: number;
    alternativeText: string;
    caption: string;
    formats: {
        thumbnail: Format;
        small: Format;
        medium: Format;
        large: Format;
    };
}

export interface IPost {
    id: number;
    text: string;
    day: string;
    images: Image[];
}

export const isArrayOfPosts = (arg: any): arg is IPost[] => {
    if (!arg) return false;
    if (arg.constructor !== Array) return false;
    if (arg.some((elem) => !isPost(elem))) return false;
    return true;
};

const isPost = (arg: any): arg is IPost => {
    return arg && typeof arg.id === 'number' && typeof arg.text === 'string';
};

export interface TextSingleType {
    content: string;
}

export const isTextSingleType = (arg: any): arg is TextSingleType => {
    return arg && arg.content && typeof arg.content === 'string';
};
