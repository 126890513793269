import { Loader } from './Loader';
import { CalendarGrid } from './CalendarGrid';
import { usePostsContext } from '../context/PostsContext';
import { LoaderView } from '../styles/components/LoaderView';
import { FC } from 'react';
import { ErrorComponent } from './Error';

export const Calendar: FC = () => {
    const state = usePostsContext();

    switch (state.type) {
        case 'LOADING':
            return (
                <LoaderView>
                    <Loader />
                </LoaderView>
            );
        case 'READY':
            return <CalendarGrid posts={state.posts} />;
        case 'ERROR':
            return <ErrorComponent message={state.message} />;
        default:
            return <ErrorComponent />;
    }
};
