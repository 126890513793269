import { ReactNode, useEffect } from 'react';
import { StyledPage } from '../styles/components/Page.style';

export interface PageProps {
    title: string;
    children?: ReactNode;
}

export const Page = (props: PageProps) => {
    const { title, children } = props;
    useEffect(() => {
        document.title = title;
    }, [title]);

    return <StyledPage>{children}</StyledPage>;
};
