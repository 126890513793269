import { Loader } from './Loader';
import { LoaderView } from '../styles/components/LoaderView';
import { usePostsContext } from '../context/PostsContext';
import { Post } from './Post';
import { ErrorComponent } from './Error';

export interface PostWrapperProps {
    id?: string;
}

export const PostWrapper = (props: PostWrapperProps) => {
    const state = usePostsContext();
    const { id } = props;

    if (!id) return <div></div>;

    const posts = state.type === 'READY' ? state.posts : [];
    const post = posts.find((post) => post.id === +id);

    switch (state.type) {
        case 'LOADING':
            return (
                <LoaderView>
                    <Loader />
                </LoaderView>
            );
        case 'READY':
            if (post) return <Post post={post} />;
            return <ErrorComponent message="Post not found or not available yet" />;
        case 'ERROR':
            return <ErrorComponent message={state.message} />;
        default:
            return <ErrorComponent />;
    }
};
