import styled from 'styled-components';

export const StyledFooter = styled.footer`
    background-color: ${({ theme }) => theme.colors.backgroundAccent};
    box-shadow: 1px 1px 10px ${({ theme }) => theme.colors.shadow};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 4px 0;
    position: absolute;
    bottom: 0;
    text-align: center;
    width: calc(100% - 20px);
    padding: 10px;
`;
