import { captureMessage } from '@sentry/minimal';
import { createContext, useContext, useEffect, useState } from 'react';
import { httpService } from '../HttpService';
import { isArrayOfPosts, IPost } from '../types';
type State =
    | { type: 'LOADING' }
    | { type: 'READY'; posts: IPost[] }
    | { type: 'ERROR'; message: string };

const PostsContext = createContext<State>({ type: 'LOADING' });

export const usePostsContext = () => {
    const ctx = useContext(PostsContext);
    if (!ctx) throw new Error('Component beyond PostsContext!');
    return ctx;
};

export const PostsContextProvider: React.FC = (props) => {
    const { children } = props;
    const [state, setState] = useState<State>({ type: 'LOADING' });

    const setError = (message: string) => {
        captureMessage(message);
        setState({
            type: 'ERROR',
            message,
        });
    };

    const setup = async () => {
        const url = `/api/posts`;
        const res = await httpService.fetch<IPost[]>(url);
        if (!res.success) {
            return setError('Failed to fetch data. Check your internet connection and try again.');
        }
        if (!isArrayOfPosts(res.data)) {
            return setError('Failed to fetch data. Check your internet connection and try again.');
        }
        const newState: State = {
            type: 'READY',
            posts: res.data.sort((a, b) => (a.day > b.day ? 1 : -1)),
        };

        setState(newState);
    };

    useEffect(() => {
        setup();
        // eslint-disable-next-line
    }, []);

    return <PostsContext.Provider value={{ ...state }}>{children}</PostsContext.Provider>;
};
