import { Header } from './components/Header';
import { ThemeProvider } from 'styled-components';
import { Navigate, Route, Routes } from 'react-router-dom';
import { PostsContextProvider } from './context/PostsContext';
import { theme } from './styles/theme';
import { GlobalStyles } from './styles/GlobalStyles';
import { HomeView, HOME_VIEW_SETTINGS } from './views/HomeView';
import { Main } from './styles/components/Main.style';
import { Footer } from './components/Footer';
import { AboutView, ABOUT_VIEW_SETTINGS } from './views/AboutView';
import { IntroductionContextProvider } from './context/IntroductionContext';
import { AboutContextProvider } from './context/AboutContext';
import { PostView, POST_VIEW_SETTINGS } from './views/PostView';
import { DATA_VIEW_SETTINGS, DataView } from './views/DataView';

function App() {
    return (
        <PostsContextProvider>
            <IntroductionContextProvider>
                <AboutContextProvider>
                    <ThemeProvider theme={theme}>
                        <GlobalStyles />
                        <Header></Header>
                        <Main>
                            <Routes>
                                <Route path={HOME_VIEW_SETTINGS.path} element={<HomeView />} />
                                <Route path={ABOUT_VIEW_SETTINGS.path} element={<AboutView />} />
                                <Route path={POST_VIEW_SETTINGS.path} element={<PostView />} />
                                <Route path={DATA_VIEW_SETTINGS.path} element={<DataView />} />
                                <Route path="*" element={<Navigate to="/" />} />
                            </Routes>
                        </Main>
                        <Footer />
                    </ThemeProvider>
                </AboutContextProvider>
            </IntroductionContextProvider>
        </PostsContextProvider>
    );
}

export default App;
