import { FC } from 'react';
import { useParams } from 'react-router';
import { Page } from '../components/Page';
import { PostWrapper } from '../components/PostWraper';
import { ViewSettings } from '../types';

export const POST_VIEW_SETTINGS: ViewSettings = {
    path: '/post/:id',
    title: 'Sustainable Christmas | Advent Calendar Post',
    navTitle: '',
};

export const PostView: FC = () => {
    const { id } = useParams();

    return (
        <Page title={POST_VIEW_SETTINGS.title}>
            <PostWrapper id={id}></PostWrapper>
        </Page>
    );
};
