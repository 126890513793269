import styled from 'styled-components';

export const StyledHeader = styled.header`
    background-color: ${({ theme }) => theme.colors.backgroundAccent};
    box-shadow: 1px 1px 10px ${({ theme }) => theme.colors.shadow};
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    min-height: 48px;

    img {
        max-width: 100%;
        height: auto;
    }

    .link {
        text-decoration: none;
        color: ${({ theme }) => theme.colors.text};
        padding: 10px 20px;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0.2px;
        margin-right: 4px;
        border-radius: ${({ theme }) => theme.borderRadius.medium};
        transition: all 0.5s;

        &:hover {
            background-color: ${({ theme }) => theme.colors.scrollbarAccent};
        }
    }

    .current {
        background-color: ${({ theme }) => theme.colors.accent};
        border-radius: ${({ theme }) => theme.borderRadius.medium};
    }

    @media screen and (max-width: 440px) {
        padding: 0 5px;
        img {
            max-width: 160px;
        }
    }
`;
