import { StyledError } from '../styles/components/Error.style';

export interface ErrorComponentProps {
    message?: string;
}

export const ErrorComponent = (props: ErrorComponentProps) => {
    return (
        <StyledError>
            <div className="header">OOPS!</div>
            <div className="error-message">{props.message || 'An unknown error occured'}</div>
        </StyledError>
    );
};
