import { FC } from 'react';
import { AboutText } from '../components/AboutText';
import { Page } from '../components/Page';
import { ViewSettings } from '../types';

export const ABOUT_VIEW_SETTINGS: ViewSettings = {
    path: '/about',
    title: 'Sustainable Christmas | About',
    navTitle: 'About',
};

export const AboutView: FC = () => {
    return (
        <Page title={ABOUT_VIEW_SETTINGS.title}>
            <AboutText />
        </Page>
    );
};
