import styled from 'styled-components';
export const StyledPage = styled.div`
    width: 60%;
    margin: 20px auto 0 auto;
    padding-bottom: 80px;
    height: calc(100% - 40px);
    position: relative;

    @media screen and (max-width: 1279px) {
        width: 70%;
    }

    @media screen and (max-width: 779px) {
        width: 80%;
    }

    @media screen and (max-width: 479px) {
        width: 90%;
    }
`;
