import { captureMessage } from '@sentry/react';
import { createContext, useContext, useEffect, useState } from 'react';
import { httpService } from '../HttpService';
import { TextSingleType } from '../types';
type State =
    | { type: 'LOADING' }
    | { type: 'READY'; about: string }
    | { type: 'ERROR'; message: string };

const AboutContext = createContext<State>({ type: 'LOADING' });

export const useAboutContext = () => {
    const ctx = useContext(AboutContext);
    if (!ctx) throw new Error('Component beyond AboutContext!');
    return ctx;
};

export const AboutContextProvider: React.FC = (props) => {
    const { children } = props;
    const [state, setState] = useState<State>({ type: 'LOADING' });

    const setError = (message: string) => {
        captureMessage(message);
        setState({
            type: 'ERROR',
            message,
        });
    };

    const setup = async () => {
        const url = `/api/about`;
        const res = await httpService.fetch<TextSingleType>(url);

        if (!res.success) {
            return setError(
                'Failed to fetch about text. Check your internet connection and try again.'
            );
        }
        const newState: State = {
            type: 'READY',
            about: res.data.content,
        };
        setState(newState);
    };
    useEffect(() => {
        setup();
        // eslint-disable-next-line
    }, []);

    return <AboutContext.Provider value={{ ...state }}>{children}</AboutContext.Provider>;
};
