import { FC } from 'react';
import { Page } from '../components/Page';
import { ViewSettings } from '../types';

export const DATA_VIEW_SETTINGS: ViewSettings = {
    path: '/data-policy',
    title: 'Sustainable Christmas | Data policy',
    navTitle: 'About',
};

export const DataView: FC = () => {
    return (
        <Page title={DATA_VIEW_SETTINGS.title}>
            <h1>Data policy</h1>
            <p>We gather telemetery data to be able to understand errors that occur on our page.</p>
            <p>
                The data is anonymous and does not include any unique device identifiers, only a
                session identifier for us to be able to reconstruct actions that led to unexpected
                behavior.
            </p>
            <p>The following pieces of information get sent to us:</p>
            <ul>
                <li>Your IP Address</li>
                <li>What browser and in which version are you using</li>
                <li>What operating sytem you are using</li>
                <li>
                    What action on our page caused the error (f.e. a requested image was not found)
                </li>
            </ul>
            <p>You can disable the telemetery by using an adblocker.</p>
        </Page>
    );
};
