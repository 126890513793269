import styled from 'styled-components';

export const StyledCalendarGrid = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(6, 1fr);
    grid-gap: 10px;
    padding-top: 10px;

    @media screen and (max-width: 1279px) {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(8, 1fr);
    }

    @media screen and (max-width: 779px) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(12, 1fr);
    }

    @media screen and (max-width: 479px) {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(24, 1fr);
    }
`;
