import styled from 'styled-components';

interface StyledCalendarGridWindowProps {
    image: string;
    contentImage?: string;
    unlocked: boolean;
}

export const StyledCalendarGridWindow = styled.div<StyledCalendarGridWindowProps>`
    background-color: ${({ theme }) => theme.colors.backgroundAccent};
    box-shadow: 1px 1px 10px ${({ theme }) => theme.colors.shadow};
    aspect-ratio: 1;

    .openable {
        position: relative;
        width: 100%;
        height: 100%;
        cursor: ${(props) => (props.unlocked ? 'pointer' : 'not-allowed')};
        transform-style: preserve-3d;
        transform: perspective(2500px);
    }
    .openable-front {
        background-color: ${({ theme }) => theme.colors.backgroundAccent};
        width: 100%;
        height: 100%;
        overflow: hidden;
        transform-origin: left;
        box-shadow: 10px 0 20px rgba(0, 0, 0, 0.2);
        position: absolute;
        z-index: 2;
        transition: 0.5s;
        background-image: url(${(props) => props.image});
        background-position: center;
        background-size: contain;
    }

    .openable-back {
        background-image: url(${(props) => props.contentImage});
        background-position: center;
        background-size: contain;
        width: 100%;
        height: 100%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 32px;
        text-shadow: 0px 0px 12px #000000;
        color: ${({ theme }) => theme.colors.background};
    }

    .openable:hover .openable-front {
        transform: ${(props) => (props.unlocked ? 'rotateY(-120deg)' : '')};
        filter: ${(props) => (props.unlocked ? '' : 'grayscale(0.8)')};
    }
`;
