import { NavLink } from 'react-router-dom';
import { StyledFooter } from '../styles/components/Footer.style';

export const Footer = () => {
    return (
        <StyledFooter>
            <div>
                © 2021 <a href="http://mikolaj.cankudis.net">Mikolaj Cankudis</a>
            </div>
            <small>
                This page sends telemetery data that enables us to fix bugs. We do not use any of
                this data to trace you, nor do we sell it to anyone.
                <NavLink to={'/data-policy'}> Read more here</NavLink>.
            </small>
        </StyledFooter>
    );
};
