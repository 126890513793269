import { StyledCalendarGrid } from '../styles/components/CalendarGrid.style';
import { CalendarWindow } from './CalendarWindow';
import { IPost } from '../types';

const images = [
    'images/1.png',
    'images/2.png',
    'images/3.png',
    'images/4.png',
    'images/5.png',
    'images/6.png',
    'images/7.png',
    'images/8.png',
    'images/9.png',
    'images/10.png',
    'images/11.png',
    'images/12.png',
    'images/13.png',
    'images/14.png',
    'images/15.png',
    'images/16.png',
    'images/17.png',
    'images/18.png',
    'images/19.png',
    'images/20.png',
    'images/21.png',
    'images/22.png',
    'images/23.png',
    'images/24.png',
];

export interface CalendarGridProps {
    posts: IPost[];
}

export const CalendarGrid = (props: CalendarGridProps) => {
    return (
        <StyledCalendarGrid>
            {images.map((url, i) => (
                <CalendarWindow url={url} key={i} post={props.posts[i]} />
            ))}
        </StyledCalendarGrid>
    );
};
