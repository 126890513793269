import { StyledCalendarGridWindow } from '../styles/components/CalendarWindow.style';
import { IPost } from '../types';
import { useNavigate } from 'react-router-dom';
export interface CalendarWindowProps {
    url: string;
    post?: IPost;
}

export const CalendarWindow = (props: CalendarWindowProps) => {
    const { post, url } = props;
    const navigate = useNavigate();

    return (
        <StyledCalendarGridWindow
            onClick={() => (post ? navigate(`/post/${post?.id}`) : '')}
            image={url}
            unlocked={post ? true : false}
            contentImage={post ? post.images[0].formats.thumbnail.url : ''}
        >
            <div className="openable">
                <div className="openable-front"></div>
                <div className="openable-back">Click Me!</div>
            </div>
        </StyledCalendarGridWindow>
    );
};
