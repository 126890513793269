import { StyledLoader } from '../styles/components/Loader';

export const Loader = () => {
    return (
        <StyledLoader>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </StyledLoader>
    );
};
